<template>
  <span>
    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>QRT - Quantidade Relativa de Reclamações Totais</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartQrtOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>PRP - Percentual de Reclamações Procedentes</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPrpOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>PSL - Percentual de Faturas Emitidas Sem Leitura</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPslOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>PRF - Percentual de Refaturamentos</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPrfOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>PCF - Percentual de Acertos de Faturamento</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPcfOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>

export default {
  name: "DashboardFaturamentoVisaoMunicipiosCardsKpisPorMunicipio",
  props: {
    dados: {
      type: Array,
      required: true,
    },
    metasKpis: {
      type: Object,
      required: true,
    },
    municipios: {
      type: Array,
      required: true,
    },
    tipoOrdenacao: {
      type: String,
      required: true,
    },
    kpiOrdenacao: {
      type: String,
      required: true,
    },
    exportingOptions : {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartQrtOptions: {},
    chartPrpOptions: {},
    chartPslOptions: {},
    chartPrfOptions: {},
    chartPcfOptions: {}
  }),
  computed: {
    categories() {
      const sortedValues = this.tipoOrdenacao === 'asc' 
        ? this.dados.sort((a, b) => Number(a[this.kpiOrdenacao] ?? 0) - Number(b[this.kpiOrdenacao] ?? 0))
        : this.dados.sort((a, b) => Number(b[this.kpiOrdenacao] ?? 0) - Number(a[this.kpiOrdenacao] ?? 0));

      return sortedValues.map(el => el.nome_municipio);
    },
  },
  mounted() {
    this.setChartsOptions();
  },
  methods: {
    setChartsOptions() {
      this.setChartQrtOptions();
      this.setChartPrpOptions();
      this.setChartPslOptions();
      this.setChartPrfOptions();
      this.setChartPcfOptions();
    },
    getColumnChartDefaultOptions(categories = this.categories) {
      return {
        chart: {
          type: 'column',
          height: 400,
          zoomType: 'x',
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: false },
        xAxis: {
          categories: categories,
          crosshair: true,
          title: { 
            text: 'Município'
          }
        },
        yAxis: {
          type: 'logarithmic',
          title: { text: null }
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: [],
        exporting: this.exportingOptions,

      }; 
    },
    setChartQrtOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "QRT",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.qrt), 
            color: Number(dados.qrt) > Number(this.metasKpis?.[dados.codigo_ibge]?.qrt) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartQrtOptions = options;
    },
    setChartPrpOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "PRP",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.prp), 
            color: Number(dados.prp) > Number(this.metasKpis?.[dados.codigo_ibge]?.prp) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartPrpOptions = options;
    },
    setChartPslOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "PSL",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.psl), 
            color: Number(dados.psl) > Number(this.metasKpis?.[dados.codigo_ibge]?.psl) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartPslOptions = options;
    },
    setChartPrfOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "PRF",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.prf), 
            color: Number(dados.prf) > Number(this.metasKpis?.[dados.codigo_ibge]?.prf) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartPrfOptions = options;
    },
    setChartPcfOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "PCF",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.pcf), 
            color: Number(dados.pcf) > Number(this.metasKpis?.[dados.codigo_ibge]?.pcf) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartPcfOptions = options;
    },
  },
  watch: {
    dados() {
      this.setChartsOptions();
    },
    categories() {
      this.setChartsOptions();
    },
  }
}

</script>

<style>

</style>